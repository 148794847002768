<template>
  <TransitionSlide name="slide-right">
    <div
      class="BaseModal"
      :class="[`size--${size}`]"
      @click="close($event)"
    >
      <div class="modal">
        <div class="modal-header">
          <BaseButton
            size="s"
            variation="tertiary"
            class="close-button"
            @click="close($event)"
          >
            X
          </BaseButton>
        </div>

        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </TransitionSlide>
</template>

<script>
import TransitionSlide from '@/transitions/TransitionBlowUp';

const sizes = ['xs', 's', 'm', 'l', 'xl'];

export default {
  components: {
    TransitionSlide,
  },

  props: {
    size: {
      type: String,
      default: sizes[0],
      validator: (size) => sizes.includes(size),
    },
  },

  methods: {
    close(event) {
      const classList = event.target.classList.value.split(' ');
      if (classList.some((targetClass) => ['BaseModal', 'close-button'].includes(targetClass))) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .BaseModal{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 45, 94, 0.4);
    display: flex;
    z-index: 20;
    padding: var(--padding);
    align-items: center;

    .modal{
      flex:1;
      display: flex;
      flex-direction: column;

      max-width: var(--max-width);
      margin: 0 auto;
      border-radius: var(--border-radius);

      background: #FFFFFF;
      box-shadow: var(--shadow-elevation-4);
     // overflow-x: auto;

     padding-bottom: 2rem;

      .modal-header,
      .modal-body {
        display: flex;

      }

      .modal-header {
        justify-content: flex-end;
        background-color: var(--secondary-color);
        padding: var(--spacing-2xs);
      }

      .modal-body {
        color: var(--dark-color-1);
        background-color: var(--light-color-1);
        flex: 1;
        display: flex;

        &::v-deep > *{
          flex: 1;
        }
      }
    }

    &.size{
      &--xs{
        --padding: var(--spacing-6xl) var(--spacing-m);
        --max-width: 600px;
        --border-radius: var(--border-radius-s);
      }
      &--s{
        --padding: var(--spacing-4xl) var(--spacing-m);
        --max-width: 600px;
        --border-radius: var(--border-radius-s);
      }

      &--m{
        --padding: var(--spacing-2xl) var(--spacing-m);
        --max-width: 800px;
        --border-radius: var(--border-radius-m);
      }

      &--l{
        --padding: var(--spacing-m);
        --max-width: 1000px;
        --border-radius: var(--border-radius-l);
      }

      &--xl{
        --padding: var(--spacing-m);
        --max-width: 1400px;
        --border-radius: var(--border-radius-l);
      }
    }
  }

</style>
