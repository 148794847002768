<template>
  <div class="BaseInput">
    <label
      v-if="label"
      :for="tag"
    >
      {{ label }}
    </label>
    <input
      :id="name"
      :type="type"
      :name="name"
      :placeholder="placeholder"
    >
  </div>
</template>

<script>
export default {
  name: 'BaseInput',

  props: {
    label: {
      type: String,
      default: undefined,
    },
    tag: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    name() {
      return this.tag;
    },
  },
};
</script>

<style lang="scss" scoped>
.BaseInput{
  input{
    border: none;
    border-bottom: solid 1px white;
    background-color: transparent;
    padding: .2rem .5rem;
    outline: none;
  }
}
</style>
