<template>
  <div id="app">
    <router-view class="view" />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
};
</script>

<style lang="scss">
@import '~@/assets/styles/_reset.scss';
@import '~@/assets/styles/app.scss';

#app {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: $spacing-xs;
  height: 100vh;
  outline: 1px solid;
  // font-family: $font-family-primary;
  font-family: $font-family-secondary;
}
</style>
