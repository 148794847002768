import Vue from 'vue';
import VueRouter from 'vue-router';
import '@/assets/styles/trivago.css';
import '@/assets/styles/tripadvisor.css';
import '@/assets/styles/booking.css';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    name: 'Home',
    path: '/home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomeView'),
  },
  {
    name: 'trivago',
    path: '/trivago',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Trivago'),
  },
  {
    name: 'tripadvisor',
    path: '/tripadvisor',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Tripadvisor'),
  },
  {
    name: 'booking',
    path: '/booking',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Booking'),
  },
  {
    name: 'finish',
    path: '/finish',
    component: () => import(/* webpackChunkName: "home" */ '@/views/FinishView'),
  },
  {
    name: 'goodbye',
    path: '/goodbye',
    component: () => import(/* webpackChunkName: "home" */ '@/views/GoodbyeView'),
  },
  {
    name: 'conditions',
    path: '/conditions',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Conditions'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
