<template>
  <div class="BaseSelect">
    <label :for="tag">{{ label }}</label>
    <select
      id=""
      v-model="innerValue"
      name=""
    >
      <option
        class="placeholder"
        :value="null"
        disabled
        selected
        hidden
      >
        Elige una opción
      </option>

      <option
        v-for="(option, i) in options"
        :key="i"
        :value="i"
      >
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
import ModelMixin from '@/mixins/ModelMixin';

export default {
  mixins: [ModelMixin],

  props: {
    label: {
      type: String,
      default: undefined,
    },

    tag: {
      type: String,
      default: undefined,
    },

    options: {
      type: null,
      required: true,
    },
  },

  created() {
  },
};
</script>

<style lang="scss" scoped>
.BaseSelect{
  display: flex;
  align-items: center;
  justify-content: space-between;
  label{
    margin-right: 1rem;
  }
  select{
    border: solid 2px var(--color-primary);
    padding: .3rem .5rem;
    background: black;
    color: white;
    width: 300px;

    outline: none;
    option[disabled]{
       color: #ccc;
    }
  }

}
</style>
