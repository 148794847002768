import axios from 'axios';
import store from '@/store';

const delayedResponse = response => new Promise(resolve => setTimeout(() => resolve(response.data), 500));

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  (config) => {
    const configuration = config;
    configuration.headers.common = {
      // 'X-API-KEY': '123456789',
      // 'BEARER-TOKEN': localStorage.getItem('user-token'),
    };
    return configuration;
  },

  error => Promise.reject(error),
);


axios.interceptors.response.use(
  (response) => {
    const isUnauthorized = response.data?.error && response.data?.status && !response.config?.url?.includes('login');
    if (isUnauthorized) store.dispatch('authModule/logout');

    return (process.env.NODE_ENV === 'offline' ? delayedResponse(response) : response.data);
  },
  (error) => {
    if (error.response?.status === 401 && !error.response?.config?.url?.includes('login')) {
      store.dispatch('authModule/logout');
    }

    return Promise.reject(error);
  },
);

export default axios;
