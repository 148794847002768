<template>
  <button
    type="button"
    class="BaseButton"
    :class="[`variation--${variation}`, `size--${size}`]"
    v-bind="$attrs"
    @click="($attrs.disabled) ? null : click()"
  >
    <slot />
  </button>
</template>

<script>
const variationValues = ['primary', 'back', 'select'];
const sizeValues = ['xs', 's', 'm', 'l'];

export default {
  props: {
    variation: {
      type: String,
      default: 'primary',
      validator: variation => variationValues.includes(variation),
    },

    size: {
      type: String,
      default: 'l',
      validator: size => sizeValues.includes(size),
    },
  },

  methods: {
    click() {
      this.$el.classList.remove('animated');
      this.$el.classList.add('animated');

      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.BaseButton {
  box-shadow: inset 0 0 0 1px var(--button-border-color);
  border: 0;
  color: var(--button-color);
  background: var(--button-background-color);
  padding: var(--button-padding);

  font-size: var(--button-font-size);
  font-weight: 500;
  text-transform: uppercase;

  position: relative;
  z-index: 1;

  display: grid;
  grid-auto-flow: column;
  gap: $spacing-xs;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  transition: all 0.3s ease;

  margin-left: 1rem;
  margin-right: 1rem;

  &::after,
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    height: 100%;
    top: 0;
    width: 0;
    left: -100%;
    transform: skew(50deg);
    transform-origin: top left;
    transition: all 0.4s ease;
  }


  &::before {
    background: var(--button-background-color-before-hover);
    transition-duration: 0.3s;
  }

  &::after {
    background: var(--button-background-color-after-hover);
    transition-duration: 0.6s;
  }

  &:hover {
    cursor: pointer;
    color: var(--button-color-hover);
    outline: none;

    &::after,
    &::before{
      width: 200%;
    }
  }

  &:focus{
    outline: none;
  }

  &.disabled{
    filter: grayscale(90%) opacity(50%);
    pointer-events: none;
    text-decoration: line-through;
  }
}

.variation {
  &--primary {
    --button-color: var(--ligth-color-0);
    --button-background-color: var(--color-primary);
    --button-color-hover: var(--ligth-color-0);
    --button-background-color-hover: var(--light-color-1);
    --button-border: 2px solid var(--color-primary);
    --button-border-hover: 1px solid transparent;
  }

  &--back {
    --button-color: var(--ligth-color-0);
    --button-background-color: var(--color-back);
    --button-color-hover: var(--ligth-color-0);
    --button-background-color-hover: var(--color-back);
    --button-border: 2px solid var(--color-back);
    --button-border-hover: 1px solid var(--light-color-1);
  }

  &--select {
    --button-color: var(--ligth-color-0);
    --button-background-color: var(--color-selected);
    --button-color-hover: var(--ligth-color-0);
    --button-background-color-hover: var(--color-selected);
    --button-border: 1px solid var(--color-selected);
    --button-border-hover: 1px solid transparent;
  }
}


.size{
  &--xs{
    --button-padding: 0;
    --button-font-size: #{$font-size-xs};
  }

  &--s{
    --button-padding: #{$spacing-xs} #{$spacing-m};
    --button-font-size: #{$font-size-xs};
  }

  &--m{
    --button-padding: #{$spacing-xs} #{$spacing-xl};
    --button-font-size: #{$font-size-s};
  }

  &--l{
    --button-padding: #{$spacing-s} #{$spacing-l};
    --button-font-size:#{$font-size-m};
  }
}
</style>
