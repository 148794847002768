var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TransitionSlide", { attrs: { name: "slide-right" } }, [
    _c(
      "div",
      {
        staticClass: "BaseModal",
        class: ["size--" + _vm.size],
        on: {
          click: function($event) {
            return _vm.close($event)
          }
        }
      },
      [
        _c("div", { staticClass: "modal" }, [
          _c(
            "div",
            { staticClass: "modal-header" },
            [
              _c(
                "BaseButton",
                {
                  staticClass: "close-button",
                  attrs: { size: "s", variation: "tertiary" },
                  on: {
                    click: function($event) {
                      return _vm.close($event)
                    }
                  }
                },
                [_vm._v(" X ")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "modal-body" }, [_vm._t("default")], 2)
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }