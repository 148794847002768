import Vue from 'vue';
import '@/config/axiosConfig';
import '@/config/baseComponentsImporter';


import App from './App';
import router from './router';
import store from './store';
import i18n from './config/i18n';

import GLOBALSETTINGS from './constants';

Vue.prototype.$settings = GLOBALSETTINGS;

Vue.config.productionTip = false;


window.Vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
