/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';
import axios from 'axios';

const getDefaultState = () => ({
  loading: undefined,
  webOrder: undefined,
  cities: undefined,
  texts: undefined,
  comments: undefined,
});

const state = () => getDefaultState();

export const getters = {
  getWebOrder: state => () => state.webOrder,
  getCities: state => () => state.cities,
  getTexts: state => () => state.texts,
};

export const mutations = {
  ...make.mutations(state),

  SET_WEB_ORDER(state, payload) {
    state.webOrder = payload;
  },

  UPDATECOMMENT_DATA(state, payload) {
    state.comments = payload;
  },
};

export const actions = {

  async setWebOrder({ commit }, payload) {
    commit('SET_WEB_ORDER', payload);
    return true;
  },

  sendLocation(context, { location, adn }) {
    const payload = {
      location_id: location,
      adn,
    };
    const url = 'experiment/setlocation';
    return axios.post(url, payload);
  },

  sendEmail(context, { email, id }) {
    const payload = {
      email,
      id,
    };
    const url = 'experiment/setEmail';
    return axios.post(url, payload);
  },

  saveInfo(context, {
    webposition, id, nextcount, backcount, trivagotime, bookingtime, tripadvisortime,
  }) {
    const payload = {
      webposition,
      id,
      nextcount,
      backcount,
      trivagotime,
      bookingtime,
      tripadvisortime,
    };
    const url = '/experiment/setElection';
    return axios.post(url, payload);
  },

  async fetchCities({ commit }) {
    const url = '/cities';
    let cities;

    try {
      cities = await axios.get(url);
      commit('SET_CITIES', cities);
    } finally {
    }

    return cities;
  },

  async fetchTexts({ commit }) {
    const url = '/texts';
    let texts;

    try {
      texts = await axios.get(url);
      commit('SET_TEXTS', texts);
    } finally {
    }

    return texts;
  },

  async fetchComments(context, { webPos, expId }) {
    const payload = {
      webPos,
      expId,
    };

    const url = '/experiment/getComments';
    const comments = await axios.post(url, payload);

    return comments;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
