var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseInput" }, [
    _vm.label
      ? _c("label", { attrs: { for: _vm.tag } }, [
          _vm._v(" " + _vm._s(_vm.label) + " ")
        ])
      : _vm._e(),
    _c("input", {
      attrs: {
        id: _vm.name,
        type: _vm.type,
        name: _vm.name,
        placeholder: _vm.placeholder
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }