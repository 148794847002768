var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseSelect" }, [
    _c("label", { attrs: { for: _vm.tag } }, [_vm._v(_vm._s(_vm.label))]),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.innerValue,
            expression: "innerValue"
          }
        ],
        attrs: { id: "", name: "" },
        on: {
          change: function($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function(o) {
                return o.selected
              })
              .map(function(o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.innerValue = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          }
        }
      },
      [
        _c(
          "option",
          {
            staticClass: "placeholder",
            attrs: { disabled: "", selected: "", hidden: "" },
            domProps: { value: null }
          },
          [_vm._v(" Elige una opción ")]
        ),
        _vm._l(_vm.options, function(option, i) {
          return _c("option", { key: i, domProps: { value: i } }, [
            _vm._v(" " + _vm._s(option) + " ")
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }